// PaymentPage.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { confirmPayment } from '../services/api'; // Импортируем функцию confirmPayment

const PaymentPage = () => {
  const { transactionId } = useParams(); // Получаем transactionId из URL
  useEffect(() => {
    // Функция для подтверждения платежа
    const handlePaymentConfirmation = async () => {
      try {
        const isConfirmed = await confirmPayment(transactionId); // Используем функцию confirmPayment
        if (isConfirmed) {
          console.log('Payment confirmed successfully!');
          if (window.Telegram.WebApp) {
            window.Telegram.WebApp.close(); // Закрываем Telegram WebApp
          }
        } else {
          console.error('Failed to confirm payment');
        }
      } catch (error) {
        console.error('Error confirming payment:', error);
      }
    };

    handlePaymentConfirmation();
  }, [transactionId]);

  return (
    <div>
      <h1>Подтверждение платежа...</h1>
      <p>Пожалуйста, подождите, пока мы подтверждаем вашу транзакцию.</p>
    </div>
  );
};

export default PaymentPage;
