import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { verifyUser, getCategories, getUserCart, addToCart, removeFromCart } from './services/api';
import CategoryContainer from './components/CategoryContainer';
import CartSidebar from './components/CartSidebar';
import CartButton from './components/CartButton';
import Orders from './components/Orders';
import Loader from './components/Loader'; // Импортируем компонент Loader
import ProductDetailsPage from './components/ProductDetailsPage'; // Импортируем компонент страницы товара
import ProductDetailsPageAlt from './components/ProductDetailsPageAlt';
import PaymentPage from './components/PaymentPage'; // Импортируем новый компонент
import BackButton from './components/BackButton';
import './App.css';

const App = () => {
  const [step, setStep] = useState(0);
  const [orderType, setOrderType] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [addressDetails, setAddressDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState('');
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Добавляем состояние загрузки

  useEffect(() => {
    if (!window.Telegram || !window.Telegram.WebApp) {
      console.error('Telegram WebApp is not available');
      setError('Telegram WebApp is not available');
      return;
    }

    const telegram = window.Telegram.WebApp;
    const user = telegram.initDataUnsafe.user;
    const authToken = telegram.initData;

    if (user && authToken) {
      setUser(user);
      setAuthToken(authToken);

      console.log('Sending verification request with:', { authToken, user });
      verifyUser(authToken, user)
        .then(response => {
          console.log('Verification response:', response.data);
          if (response.data.success) {
            console.log(`Auth successful! Telegram ID: ${response.data.user.id}`);
            fetchCategories();
          } else {
            console.error('Auth failed!');
            // setError('Authentication failed.');
            setLoading(false);
          }
        })
        .catch(error => {
          console.error('Error verifying auth:', error);
          // setError('Authentication failed.');
          setLoading(false);
        });
    } else {
      console.error('User information is not available');
      // setError('User information is not available.');
      setLoading(false);
    }
  }, []);

  const fetchCategories = () => {
    getCategories(user.id) // вызов API
      .then(response => {
        // Фильтруем категории с level 0
        const filteredCategories = response.data.category.filter(cat => cat.level === 0);
        setCategories(filteredCategories); // Обновляем состояние категорий
        setLoading(false); // Убираем состояние загрузки
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setLoading(false); // Убираем состояние загрузки в случае ошибки
      });
  };

  const fetchUserCart = () => {
    if (user) {
      getUserCart(user.id)
        .then(response => {
          setCart(response.data.items);
        })
        .catch(error => {
          console.error('Error fetching user cart:', error);
        });
    }
  };

  const handleAddToCart = (cardId) => {
    if (user) {
      addToCart(user.id, cardId)
        .then(() => {
          fetchUserCart();
        })
        .catch(error => {
          console.error('Error adding item to cart:', error);
        });
    }
  };

  const handleRemoveFromCart = (cardId) => {
    if (user) {
      removeFromCart(user.id, cardId)
        .then(() => {
          fetchUserCart();
        })
        .catch(error => {
          console.error('Error removing item from cart:', error);
        });
    }
  };

  const handleBack = () => {
    if (selectedCategory) {
      setSelectedCategory(null);
    } else if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleCartButtonClick = () => {
    fetchUserCart();
    setIsCartOpen(true);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return <Loader />; // Показ прелоадера при загрузке данных
  }

  return (
    <Router>
      <div className="container">
        <Routes>
          {/* Новый роут для страницы оплаты */}
          <Route path="/payment/:transactionId" element={<PaymentPage />} />
          <Route path="/order" element={<Orders userId={user ? user.id : null} />} />

          <Route path="/product/:productId" element={<ProductDetailsPage />} />
          <Route path="/product-alt/:productId" element={<ProductDetailsPageAlt />} />
          <Route path="/" element={
            <>
              <CategoryContainer
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                onAddToCart={handleAddToCart}
                cart={cart}
                onRemoveFromCart={handleRemoveFromCart}
              />
              {selectedCategory && (
                  <BackButton onClick={handleBack} />                
              )}
              <CartButton onClick={handleCartButtonClick} />
              <CartSidebar
                cart={cart}
                onAdd={handleAddToCart}
                onRemove={handleRemoveFromCart}
                isOpen={isCartOpen}
                setIsOpen={setIsCartOpen}
                userId={user ? user.id : null}
              />
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
