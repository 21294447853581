// src/components/ProductDetailsModal.js
import React, { useState, useEffect } from 'react';
import './ProductDetailsModal.css'; // Стили для модального окна
import ComboProductDetails from './ComboProductDetails'; // Импортируем компонент для отображения товаров комбо
import { ReactComponent as CloseIcon } from './cross.svg';
const ProductDetailsModal = ({ product, onClose, onAddToCart, hideAddToCartButton }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryRestrictions, setCategoryRestrictions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedCombos, setSelectedCombos] = useState([]);

  useEffect(() => {
    if (product.productVariants && product.productVariants.length > 0) {
      const defaultVariant = product.productVariants[0];
      setSelectedVariant(defaultVariant);
      setSelectedOptions(defaultVariant.optionIds);
      updateCategoryRestrictions(defaultVariant);
    } else if (product.optionCategories) {
      const defaultRestrictions = {};
      product.optionCategories.forEach(optionCategory => {
        const { min, max } = getOptionRestrictions(optionCategory);
        defaultRestrictions[optionCategory.optionCategoryId] = { min, max };
      });
      setCategoryRestrictions(defaultRestrictions);
    } else {
      setTotalPrice(product.price || 0);
    }

    const defaultOptions = [];
    product.optionCategories?.forEach(optionCategory => {
      optionCategory.options.forEach(option => {
        if (option.restrictions && option.restrictions.isDefault) {
          defaultOptions.push(option.optionId);
        }
      });
    });

    setSelectedOptions(prevOptions => [...new Set([...prevOptions, ...defaultOptions])]);
  }, [product]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedVariant, selectedOptions]);

  const updateCategoryRestrictions = (variant) => {
    const newRestrictions = {};

    product.optionCategories?.forEach(optionCategory => {
      const { min, max } = getOptionRestrictions(optionCategory, variant);
      newRestrictions[optionCategory.optionCategoryId] = { min, max };
    });

    setCategoryRestrictions(newRestrictions);
  };

  const getOptionRestrictions = (optionCategory, variant = selectedVariant) => {
    if (optionCategory.restrictions && optionCategory.restrictions.min !== null && optionCategory.restrictions.max !== null) {
      return {
        min: optionCategory.restrictions.min,
        max: optionCategory.restrictions.max,
      };
    } else if (variant && optionCategory.productVariantRestrictions) {
      const variantRestrictions = optionCategory.productVariantRestrictions.productVariantId[variant.productVariantId];
      if (variantRestrictions) {
        return {
          min: variantRestrictions.min !== undefined ? variantRestrictions.min : 1,
          max: variantRestrictions.max !== undefined ? variantRestrictions.max : 10,
        };
      }
    }
    return { min: 0, max: 10 };
  };

  const calculateTotalPrice = () => {
    let price = selectedVariant ? selectedVariant.price : product.price;

    selectedOptions.forEach(optionId => {
      const option = product.optionCategories?.flatMap(category => category.options).find(option => option.optionId === optionId);
      if (option && option.price) {
        price += option.price;
      }
    });

    setTotalPrice(price);
  };

  const handleOptionSelect = (optionId, max, min, optionCategoryId) => {
    const categoryOptions = product.optionCategories.find(category => category.optionCategoryId === optionCategoryId)?.options || [];
    const selectedInCategory = selectedOptions.filter(optionId => categoryOptions.some(option => option.optionId === optionId));

    if (min === 1 && max === 1) {
      const newSelected = selectedOptions.filter(id => !selectedInCategory.includes(id));
      setSelectedOptions([...newSelected, optionId]);

      const relatedVariant = product.productVariants?.find(variant => variant.optionIds.includes(optionId));
      if (relatedVariant) {
        handleVariantSelect(relatedVariant);
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter(id => id !== optionId));
      } else if (selectedInCategory.length < max) {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    }
  };

  const handleVariantSelect = (variant) => {
    if (!variant) return;
    setSelectedVariant(variant);
    updateCategoryRestrictions(variant);
    filterExcessOptions(variant);
    setSelectedOptions(prevOptions => {
      const optionsWithoutOldVariant = prevOptions.filter(optionId => !selectedVariant?.optionIds.includes(optionId));
      return [...optionsWithoutOldVariant, ...variant.optionIds];
    });
  };

  const filterExcessOptions = (variant) => {
    const updatedOptions = [...selectedOptions];

    product.optionCategories?.forEach(optionCategory => {
      const { max } = getOptionRestrictions(optionCategory, variant);

      const categoryOptions = optionCategory.options.map(option => option.optionId);
      const selectedInCategory = updatedOptions.filter(optionId => categoryOptions.includes(optionId));

      while (selectedInCategory.length > max) {
        const optionToRemove = selectedInCategory.pop();
        updatedOptions.splice(updatedOptions.indexOf(optionToRemove), 1);
      }
    });

    setSelectedOptions(updatedOptions);
  };

  const handleComboChange = (combos) => {
    setSelectedCombos(combos);
  };

  const handleAddToCart = () => {
    // Если есть выбранные комбо, отправляем только комбо
    if (selectedCombos.length > 0) {
      const productToAdd = {
        products: [], // Товары не отправляем
        combos: selectedCombos.map(combo => ({
          ...combo,
          programId: product.productId, // Устанавливаем programId как айди текущего продукта
          name: product.name,
          price:product.price
        })) // Отправляем комбо в нужной структуре
      };
      onAddToCart(productToAdd); 
    } else {
      // Если комбо не выбраны, отправляем обычный товар
      const allCategoriesValid = product.optionCategories?.every(optionCategory => {
        const selectedInCategory = selectedOptions.filter(optionId =>
          optionCategory.options.some(option => option.optionId === optionId)
        );

        const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };
        const count = selectedInCategory.length;
        return count >= min && count <= max;
      });

      if (allCategoriesValid || !product.optionCategories) {
        const productToAdd = {
          products: [
            {
              modifierIds: selectedOptions,
              productId: product.productId,
              comment: "", // можете добавить поле для комментариев, если нужно
              amount: 1, // или измените на нужное значение количества
              isExcise: product.isExcise || false, // или установите по умолчанию false
            },
          ],
          combos: [] // Комбо пусто, так как не выбрано
        };

        onAddToCart(productToAdd); 
      } else {
        alert('Пожалуйста, выберите допустимое количество опций в каждой категории.');
      }
    }
    onClose();
  };

  // Закрываем модальное окно при клике за его пределами
  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <div className="modal-content">
        {/* Добавляем SVG иконку крестика слева сверху */}
        <div className="modal-header">
          <CloseIcon className="close-icon" onClick={onClose} />
          <h2>{product.name}</h2>
        </div>
        <p>{product.description}</p>

        {selectedVariant ? (
          <>
            <p>Цена: {totalPrice} руб.</p>
            <p>Вес: {selectedVariant.weight} кг</p>
          </>
        ) : (
          <>
            <p>Цена: {product.price} руб.</p>
            {product.weight && <p>Вес: {product.weight} кг</p>}
          </>
        )}

        {/* Используем компонент для отображения товаров комбо */}
        {product.comboGroupList && (
          <ComboProductDetails comboGroupList={product.comboGroupList} onComboChange={handleComboChange} />
        )}

        {product.optionCategories && (
          <div className="options-container">
            {product.optionCategories.map(optionCategory => {
              const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };

              return (
                <div key={optionCategory.optionCategoryId} className="option-category">
                  <h3>{optionCategory.name}</h3>
                  {/* <p>Выберите от {min} до {max} опций</p> */}
                  <div className="options">
                    {optionCategory.options.map(option => (
                      <div
                        key={option.optionId}
                        className={`option ${selectedOptions.includes(option.optionId) ? 'selected' : ''}`}
                        onClick={() => handleOptionSelect(option.optionId, max, min, optionCategory.optionCategoryId)}
                      >
                        {/* <img src={`https://novosibirsk.grilnica.ru/cdn/${option.iconFileName}`} alt={option.name} /> */}
                        <p>{option.name}</p>
                        {option.price !== 0 && <p>{option.price} руб.</p>}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Кнопка "Добавить в корзину" отображается только для основного товара, а не для товаров в комбо */}
        {!hideAddToCartButton && (
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            Добавить в корзину
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsModal;
