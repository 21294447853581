// src/components/CartButton.js
import React from 'react';
import { ReactComponent as CartIcon } from './cart.svg';
import styles from './CartButton.module.css'; // Импортируем стили как модуль

const CartButton = ({ onClick }) => (
  <div className={styles.cartButton} onClick={onClick}>
    <CartIcon className={styles.cartIcon} />
  </div>
);

export default CartButton;