// src/components/ProductDetailsPage.js
import React, { useState, useEffect } from 'react';
import './ProductDetailsPage.css'; // Стили для страницы товара
import ComboProductDetails from './ComboProductDetails'; // Компонент для отображения товаров комбо
import { useParams } from 'react-router-dom';
import { fetchProductById, addToCartFromRec } from '../services/api'; // Импорт функции для получения данных о продукте и добавления в корзину
const telegram = window.Telegram.WebApp;
const user = telegram.initDataUnsafe.user;
const authToken = telegram.initData;
const ProductDetailsPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryRestrictions, setCategoryRestrictions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedCombos, setSelectedCombos] = useState([]); // Для хранения комбо

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const data = await fetchProductById(productId, user.id);
        setProduct(data);

        if (data.productVariants && data.productVariants.length > 0) {
          const defaultVariant = data.productVariants[0];
          setSelectedVariant(defaultVariant);
          setSelectedOptions(defaultVariant.optionIds);
          updateCategoryRestrictions(defaultVariant, data);
        } else if (data.optionCategories) {
          const defaultRestrictions = {};
          data.optionCategories.forEach(optionCategory => {
            const { min, max } = getOptionRestrictions(optionCategory);
            defaultRestrictions[optionCategory.optionCategoryId] = { min, max };
          });
          setCategoryRestrictions(defaultRestrictions);
        } else {
          setTotalPrice(data.price || 0);
        }

        const defaultOptions = [];
        data.optionCategories?.forEach(optionCategory => {
          optionCategory.options.forEach(option => {
            if (option.restrictions && option.restrictions.isDefault) {
              defaultOptions.push(option.optionId);
            }
          });
        });

        setSelectedOptions(prevOptions => [...new Set([...prevOptions, ...defaultOptions])]);
      } catch (error) {
        console.error('Ошибка загрузки продукта:', error);
      }
    };

    loadProduct();
  }, [productId]);

  useEffect(() => {
    if (product) {
      calculateTotalPrice();
    }
  }, [selectedVariant, selectedOptions, product]);

  const updateCategoryRestrictions = (variant, productData = product) => {
    const newRestrictions = {};

    productData.optionCategories?.forEach(optionCategory => {
      const { min, max } = getOptionRestrictions(optionCategory, variant);
      newRestrictions[optionCategory.optionCategoryId] = { min, max };
    });

    setCategoryRestrictions(newRestrictions);
  };

  const getOptionRestrictions = (optionCategory, variant = selectedVariant) => {
    if (optionCategory.restrictions && optionCategory.restrictions.min !== null && optionCategory.restrictions.max !== null) {
      return {
        min: optionCategory.restrictions.min,
        max: optionCategory.restrictions.max,
      };
    } else if (variant && optionCategory.productVariantRestrictions) {
      const variantRestrictions = optionCategory.productVariantRestrictions.productVariantId[variant.productVariantId];
      if (variantRestrictions) {
        return {
          min: variantRestrictions.min !== undefined ? variantRestrictions.min : 1,
          max: variantRestrictions.max !== undefined ? variantRestrictions.max : 10,
        };
      }
    }
    return { min: 0, max: 10 };
  };

  const calculateTotalPrice = () => {
    let price = selectedVariant ? selectedVariant.price : product.price;

    selectedOptions.forEach(optionId => {
      const option = product.optionCategories?.flatMap(category => category.options).find(option => option.optionId === optionId);
      if (option && option.price) {
        price += option.price;
      }
    });

    setTotalPrice(price);
  };

  const handleOptionSelect = (optionId, max, min, optionCategoryId) => {
    const categoryOptions = product.optionCategories.find(category => category.optionCategoryId === optionCategoryId)?.options || [];
    const selectedInCategory = selectedOptions.filter(optionId => categoryOptions.some(option => option.optionId === optionId));

    if (min === 1 && max === 1) {
      const newSelected = selectedOptions.filter(id => !selectedInCategory.includes(id));
      setSelectedOptions([...newSelected, optionId]);

      const relatedVariant = product.productVariants?.find(variant => variant.optionIds.includes(optionId));
      if (relatedVariant) {
        handleVariantSelect(relatedVariant);
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter(id => id !== optionId));
      } else if (selectedInCategory.length < max) {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    }
  };

  const handleVariantSelect = (variant) => {
    if (!variant) return;
    setSelectedVariant(variant);
    updateCategoryRestrictions(variant);
    filterExcessOptions(variant);
    setSelectedOptions(prevOptions => {
      const optionsWithoutOldVariant = prevOptions.filter(optionId => !selectedVariant?.optionIds.includes(optionId));
      return [...optionsWithoutOldVariant, ...variant.optionIds];
    });
  };

  const filterExcessOptions = (variant) => {
    const updatedOptions = [...selectedOptions];

    product.optionCategories?.forEach(optionCategory => {
      const { max } = getOptionRestrictions(optionCategory, variant);

      const categoryOptions = optionCategory.options.map(option => option.optionId);
      const selectedInCategory = updatedOptions.filter(optionId => categoryOptions.includes(optionId));

      while (selectedInCategory.length > max) {
        const optionToRemove = selectedInCategory.pop();
        updatedOptions.splice(updatedOptions.indexOf(optionToRemove), 1);
      }
    });

    setSelectedOptions(updatedOptions);
  };

  const handleComboChange = (combos) => {
    setSelectedCombos(combos); // Сохраняем выбранные комбо
  };

  const handleAddToCartFromRec = () => {
    const allCategoriesValid = product.optionCategories?.every(optionCategory => {
      const selectedInCategory = selectedOptions.filter(optionId =>
        optionCategory.options.some(option => option.optionId === optionId)
      );

      const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };
      const count = selectedInCategory.length;
      return count >= min && count <= max;
    });

    if (allCategoriesValid || !product.optionCategories) {
      // Формируем объект для добавления в корзину
      const productToAdd = {
        products: [
          {
            modifierIds: selectedOptions,
            productId: product.productId,
            comment: "",
            amount: 1,
            isExcise: product.isExcise || false,
          },
        ],
        combos: selectedCombos.map(combo => ({
          ...combo,
          programId: product.productId, // Устанавливаем programId как айди продукта
          name: product.name,
          price:product.price
        })),
      };

      // Используем функцию addToCartFromRec для отправки запроса
      addToCartFromRec(productToAdd)
        .then(response => {
          console.log('Товар успешно добавлен в корзину:', response);
          // Закрываем окно Telegram WebApp
          if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.close();
          }
        })
        .catch(error => {
          console.error('Ошибка при добавлении товара в корзину:', error);
          alert('Ошибка при добавлении товара в корзину.');
        });
    } else {
      alert('Пожалуйста, выберите допустимое количество опций в каждой категории.');
    }
  };

  if (!product) {
    return <div>Загрузка...</div>;
  }
  
  return (
    <div className="product-page">
      <h1>{product.name}</h1>
      <p>{product.description}</p>
      <img src={`https://novosibirsk.grilnica.ru/cdn/${product.imageFileName}`} alt={product.name} />

      {selectedVariant ? (
        <>
          <p>Цена: {totalPrice} руб.</p>
          <p>Вес: {selectedVariant.weight} кг</p>
        </>
      ) : (
        <>
          <p>Цена: {product.price} руб.</p>
          {product.weight && <p>Вес: {product.weight} кг</p>}
        </>
      )}

      {product.comboGroupList && (
        <ComboProductDetails comboGroupList={product.comboGroupList} onComboChange={handleComboChange} />
      )}

      {product.optionCategories && (
        <div className="options-container">
          {product.optionCategories.map(optionCategory => {
            const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };

            return (
              <div key={optionCategory.optionCategoryId} className="option-category">
                <h3>{optionCategory.name}</h3>
                <div className="options">
                  {optionCategory.options.map(option => (
                    <div
                      key={option.optionId}
                      className={`option ${selectedOptions.includes(option.optionId) ? 'selected' : ''} ${selectedVariant?.optionIds.includes(option.optionId) ? 'variant-selected' : ''}`}
                      onClick={() => handleOptionSelect(option.optionId, max, min, optionCategory.optionCategoryId)}
                    >
                      {/* <img src={`https://novosibirsk.grilnica.ru/cdn/${option.iconFileName}`} alt={option.name} /> */}
                      <p>{option.name}</p>
                      {option.price !== 0 && <p>{option.price} руб.</p>}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <button className="add-to-cart-button" onClick={handleAddToCartFromRec}>
        Добавить в корзину
      </button>
    </div>
  );
};

export default ProductDetailsPage;
